import { ReactElement, useEffect } from 'react'
import { AppProps, NextWebVitalsMetric } from 'next/app'
import Head from 'next/head'

import { Favicons } from '~/components/atoms'
import { AppProvider, UserProvider, ThemeProvider } from '~/contexts'
import { DefaultTheme } from '~/themes'
import { getGoogleFontsUrl } from '~/util'

// TODO: Investigate implementing web vitals logging
// export function reportWebVitals(metric: NextWebVitalsMetric) {
// 	console.log('metric', metric)
// }

function App({ Component, pageProps }: AppProps): ReactElement {
	const fontsUrl = getGoogleFontsUrl(DefaultTheme.theme.font)

	return (
		<>
			<Head>
				<meta
					key="viewport"
					name="viewport"
					content="minimum-scale=1, initial-scale=1, width=device-width"
				/>
				<link
					key="pre-google-fonts"
					rel="preconnect"
					href="https://fonts.gstatic.com"
				/>
				<link rel="preload" as="style" href={fontsUrl} />
				<link key="google-fonts" href={fontsUrl} rel="stylesheet" />
			</Head>
			<Favicons />

			<DefaultTheme.GlobalStyle />

			<AppProvider>
				<UserProvider>
					<ThemeProvider theme={DefaultTheme.theme}>
						<Component {...pageProps} />
					</ThemeProvider>
				</UserProvider>
			</AppProvider>
		</>
	)
}

export default App
